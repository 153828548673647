import update from "immutability-helper";
import { useContext, useState } from "react";
import { MoveCard } from "./MoveCard";
import { languages } from "../../../App";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
// import Popupclose from "../../../_Components/Utils/Popup/PopupClose";

const AdminDashboard = () => {
  let curLanguage = useContext(languages);
  let courseinfo = localStorage.getItem("courseDetails");
  const courseinfoObject = JSON.parse(courseinfo);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  {
    const [cards, setCards] = useState([
      {
        id: 1,
        text: curLanguage.admission,
        links: "/admissionForm",
        icons: (
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/admission.svg`}
            alt="admission"
            width={110}
          />
        ),
        customclass: "green",
      },
      {
        id: 3,
        text: curLanguage.attendance,
        links: "/adminAttendance",
        // clickHandler: handleClickOpen,
        icons: (
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/attendance.svg`}
            alt="attendance"
            width={75}
          />
        ),
        customclass: "orange",
      },
      {
        id: 5,
        text: curLanguage.annualPlanner,
        links: "/annualplanner",
        icons: (
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/planner.svg`}
            alt="certificate"
            width={120}
          />
        ),
        customclass: "yellow",
      },

      {
        id: 4,
        text: curLanguage.createcertificate,
        links: "/add-certificate",
        icons: (
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/certificate.svg`}
            alt="certificate"
            width={120}
          />
        ),
        customclass: "brown",
      },
      {
        id: 8,
        text: curLanguage.calendar,
        links: "/calendar",
        icons: (
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/calendar.svg`}
            alt="certificate"
            width={120}
          />
        ),
        customclass: "purple",
      },
      {
        id: 2,
        text: curLanguage.feecollection,
        links: "/feeCollection",
        icons: (
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/fees.svg`}
            alt="fees"
            width={75}
          />
        ),
        customclass: "red",
      },

      {
        id: 6,
        text: curLanguage.timeTable,
        links: "/assignTimeTable",
        icons: (
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/timetable.svg`}
            alt="certificate"
            width={120}
          />
        ),
        customclass: "blue",
      },
      {
        id: 7,
        text: curLanguage.usermgmt,
        links: "/userManagement",
        icons: (
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/usermanagement.svg`}
            alt="certificate"
            width={120}
          />
        ),
        customclass: "darkblue",
      },
    ]);
    const moveCard = (dragIndex, hoverIndex) => {
      setCards((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        })
      );
    };

    const renderCard = (card, index) => {
      return (
        <MoveCard
          key={card.id}
          index={index}
          id={card.id}
          text={card.text}
          clickHand={card.clickHandler}
          links={card.links}
          moveCard={moveCard}
          icons={card.icons}
          customclass={card.customclass}
        />
      );
    };
    return (
      <>
        <Grid container spacing={3} mb={2} sx={{ justifyContent: "center" }}>
          {cards.map((card, i) => renderCard(card, i))}
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{
              backgroundColor: "popup.titleHeaderColor",
              color: "popup.popupheadertextcolor",
              mb: 4,
            }}
          >
            {curLanguage.attendance}
            {/* <Popupclose close={handleClose} /> */}
          </DialogTitle>
          <DialogContent
            sx={{
              backgroundColor: "cardColor.main",
              color: "cardColor.contrast",
            }}
            className="adminInSec"
          >
            {courseinfoObject?.map((course) => {
              return (
                <Link
                  rel="preload"
                  key={course.id}
                  to={"/attendance"}
                  className="adminInList"
                  state={{
                    id: course.id,
                    fullname: course.fullname,
                  }}
                >
                  <ListItemText
                    value={course.fullname}
                    sx={{ color: "#fff" }}
                    divider={true}
                    dense={true}
                    className="listHead"
                  >
                    <div>
                      <img
                        src={`${process.env.REACT_APP_wsPrefix}/lms-assets/icons/attendance2.svg`}
                        alt="attendanceIcon"
                        width={60}
                      />
                    </div>
                    {course.fullname}
                  </ListItemText>
                </Link>
              );
            })}
          </DialogContent>
        </Dialog>
      </>
    );
  }
};

export default AdminDashboard;
