import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import "./modAccessInformation.css";
import axios from "axios";
import { useState } from "react";
import { Box, Button, Card, Container, Typography } from "@mui/material";
import BackButton from "../../_Components/Common/BackBtn/BackBtn";

const  ModAccessInformation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const userinfo = localStorage.getItem("userInfo");
  const userinfoObject = JSON.parse(userinfo);
  const nToken = localStorage.getItem("nToken");
  const mToken = localStorage.getItem("mToken");
  const course = JSON.parse(localStorage.getItem("courseDetails"));
  const searchParams = new URLSearchParams(location.search);

  const modValueID = searchParams.get("id");
  const courseID = searchParams.get("courseID");
  const title = searchParams.get("title");
  const modname = searchParams.get("modname");
  const classid = searchParams.get("classid");
  const url = decodeURIComponent(searchParams.get("url"));
  const cmid = searchParams.get("cmid");
  const stdclass = searchParams.get("class");
  const themename = searchParams.get("themename");
  const modulename = searchParams.get("modulename");

  const [quizStatus, setQuizStatus] = useState();
  const [quizInfo, setQuizInfo] = useState();
  const [studentLoading, setStudentLoading] = useState(false);
  const [instance, setInstance] = useState("");

  // quiz progress
  const getQuizStatusURL = `${process.env.REACT_APP_wsPrefix}customwebservice/quiz/quizattempt.php?wsfunction=getquiz_attemptdata&wstoken=${token}`;

  // quiz time and attempt
  const quizInfoURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_get_quiz_access_information&moodlewsrestformat=json&quizid=${modValueID}`;
  // First attempt
  const attemptURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_start_attempt&moodlewsrestformat=json&quizid=${modValueID}`;

  const parameters = {
    quiz_id: modValueID,
    user_id: userinfoObject[0].id,
  };

  useEffect(() => {
    const getQuizStatus = () => {
      console.log(parameters, "para", getQuizStatusURL, parameters);
      axios
        .post(getQuizStatusURL, JSON.stringify(parameters))
        .then((response) => {
          setQuizStatus(response.data);
        })
        .catch((err) => {
          console.log("Problem in fetching data");
        });
    };
    if (modValueID === undefined || modValueID === null) {
      const instanceURL = `${process.env.REACT_APP_nodewsPrefix}/getInstanceByCMID?cmid=${cmid}`;

      axios
        .get(instanceURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        })
        .then((res) => {
          setInstance(res.data.instance.instance);
        });
    } else {
      setInstance(modValueID);
    }

    const getQuizInformation = () => {
      axios.get(quizInfoURL).then((response) => {
        setQuizInfo(response.data);
      });
    };

    getQuizStatus();
    getQuizInformation();
  }, []);

  const setLink = (title, scormid) => {
    console.log(instance, "instanceinstance");

    let links;
    links = `${process.env.REACT_APP_wsPrefix}/mod/scorm/scormplayer.php?a=${instance}&currentorg=ratnasagar&scoid=${scormid}&sesskey=hP1eN08thy&display=popup&mode=normal&b-token=${nToken}&token=${mToken}&cid=${classid}`;
    console.log(links, "linkslinks");
    return links;
  };

  const openLinkInTab = () => {
    console.log(instance, "instanceinstance");
    axios
      .get(`${process.env.REACT_APP_nodewsPrefix}/getScoid?scormid=${instance}`)
      .then((response) => {
        window.open(setLink(title, response.data.id), "");
      });
  };

  let quizProgress;

  if (quizStatus) {
    console.log(quizStatus);
    if (quizStatus[0].length < 1) {
      quizProgress = 0;
    } else if (quizStatus[1][0] === "inprogress") {
      console.log("Inprogress");
      quizProgress = 1;
    } else if (quizStatus[1][0] === "finished") {
      console.log("finished");
      quizProgress = 2;
    }
  }

  if (quizInfo) {
    console.log(quizInfo);
  }

  const handleResource = () => {
    window.open(url, "_blank");
  };

  const handleUrl = () => {
    const courseName = course.filter((data) => data.id === Number(courseID));
    const urlWithId = `${url}?userid=${parameters.user_id}&stdclass=${stdclass}&coursetype=${courseName[0]?.displayname}&coursename=${courseName[0]?.fullname}&themename=${themename}&modulename=${modulename}`;
    window.open(urlWithId, "_blank");
  };

  const startAttempt = () => {
    if (modname === "quiz") {
      if (quizProgress === 0) {
        // NEW ATTEMPT
        axios.get(attemptURL).then((response) => {
          navigate("/AssessmentAttempt", {
            state: { id: response.data.attempt.id, quizID: modValueID },
          });
        });
      } else if (quizProgress === 1) {
        // IN PROGRESS
        console.log(quizStatus[0][0]);
        navigate("/AssessmentAttempt", {
          state: { id: quizStatus[0][0], quizID: modValueID },
        });
      }

      const recentUrl = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=mod_quiz_view_quiz&moodlewsrestformat=json&quizid=${modValueID}`;
      return axios.get(recentUrl);
    } else {
      openLinkInTab();
      const recentUrl = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&scormid=${modValueID}&wsfunction=mod_scorm_view_scorm`;
      return axios.get(recentUrl);
    }
  };

  const viewAttemptsHandler = () => {
    setStudentLoading(true);
    let studentListURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_enrol_get_enrolled_users_info&moodlewsrestformat=json&courseid=${courseID}`;

    axios.get(studentListURL).then((response) => {
      let studentsList = response.data.filter(
        (user) => user.department?.toLowerCase() === "student"
      );

      navigate("/GetCourseContent/modAccessInformation/quizstudentlist", {
        state: {
          list: studentsList,
          title: title,
          quizID: modValueID,
        },
      });
    });
  };

  return (
    <div className="dashboardMain">
      {quizInfo && console.log(quizInfo.accessrules)}
      <Container maxWidth="xl">
        <Card
          maxWidth="xl"
          sx={{
            p: 0,
            mb: 4,
            backgroundColor: "cardColor.main",
            color: "cardColor.contrast",
          }}
          elevation={5}
          className="customCard"
        >
          <Typography
            className="title"
            variant="h5"
            px={2}
            py={1}
            sx={{ borderColor: "borderLine.main", marginBottom: "30px" }}
          >
            <Box className="titleHead">
              <BackButton />
              <b>{title}</b>
            </Box>
          </Typography>
          <Box className="customCardContent">
            {modname === "quiz" &&
              quizInfo &&
              quizInfo.accessrules?.map((info) => {
                return (
                  <Typography variant="h6" className="AssessmentHeading">
                    {info}
                  </Typography>
                );
              })}
            {modname === "quiz" &&
              userinfoObject &&
              userinfoObject[0].department?.toLowerCase() === "student" && (
                <Button
                  color="buttons"
                  variant="contained"
                  sx={{ margin: "20px" }}
                  onClick={startAttempt}
                >
                  {quizProgress === 0 && "Start Attempt"}
                  {quizProgress === 1 && "Attempt in progress"}
                  {quizProgress === 2 && "Attempt Finsihed"}
                </Button>
              )}

            {modname.toLowerCase() === "url" &&
              title.toLowerCase() === "lesson plan" &&
              userinfoObject &&
              userinfoObject[0].department?.toLowerCase() === "pedagogy" && (
                <Button
                  color="buttons"
                  variant="contained"
                  sx={{ margin: "20px" }}
                  onClick={handleUrl}
                >
                  Open
                </Button>
              )}
            {modname === "quiz" &&
              userinfoObject &&
              userinfoObject[0].department === "Pedagogy" && (
                <Button
                  color="buttons"
                  variant="contained"
                  sx={{ margin: "20px" }}
                  onClick={viewAttemptsHandler}
                  className="btn"
                >
                  View Attempts
                </Button>
              )}

            {modname === "scorm" && (
              <Button
                color="buttons"
                variant="contained"
                sx={{ margin: "20px" }}
                onClick={startAttempt}
                className="btn"
              >
                Start
              </Button>
            )}

            {modname === "resource" && (
              <Button
                color="buttons"
                variant="contained"
                sx={{ margin: "20px" }}
                onClick={handleResource}
                className="btn"
              >
                Start
              </Button>
            )}
          </Box>
        </Card>
      </Container>
    </div>
  );
}

export default ModAccessInformation;
