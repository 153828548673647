import {
    Box,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
  } from "@mui/material";
  import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import axios from "axios";
  import { useEffect, useState } from "react";
  import { PrimaryBgBtn } from "../../_Components/Common/Buttons/CustomBtns";
  import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
  
  const EventPlanner = () => {
    const userinfo = JSON.parse(localStorage.getItem("userInfo"));
    const token = localStorage.getItem("token");
    const [stdClassInfo, setStdClassInfo] = useState([]);
    const [classes, setClasses] = useState([]);
    const [sections, setSections] = useState([]);
    const [subjectsVal, setSubjectsVal] = useState([]);
    const nToken = localStorage.getItem("nToken");
    const [formValues, setFormValues] = useState([
      {
        title:"",
        createFor:"",
        stdClass: "",
        stdsection: "",
        subject: "",
        date: null,
        duration: "",
      },
    ]);
  
    const handleInputData = (index, event) => {
      const { name, value } = event.target;
      const newFormValues = [...formValues];
      newFormValues[index][name] = value;
      setFormValues(newFormValues);
      if (name === "stdClass" || name === "stdsection") {
        const selectedClass =
          name === "stdClass" ? value : newFormValues[index].stdClass;
        const selectedSection =
          name === "stdsection" ? value : newFormValues[index].stdsection;
  
        if (selectedClass && selectedSection) {
          const selectedClassInfo = stdClassInfo.find(
            (item) =>
              item.class === parseInt(selectedClass) &&
              item.section === selectedSection
          );
  
          if (selectedClassInfo) {
            fetchSubjects(selectedClassInfo.group_name, index);
          }
        }
      }
    };
  
    const handleDateChange = (index, date) => {
      const newFormValues = [...formValues];
      newFormValues[index].date = date;
      setFormValues(newFormValues);
      console.log(date, "dateeee");
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const baseURL = `${process.env.REACT_APP_nodewsPrefix}/FetchClassSection?schoolcode=${userinfo[0]?.institution}`;
          const response = await axios.get(baseURL, {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          });
          const data = response.data.class_section;
          setStdClassInfo(data);
          const uniqueClasses = [...new Set(data.map((item) => item.class))];
          setClasses(uniqueClasses);
          const uniqueSections = [...new Set(data.map((item) => item.section))];
          setSections(uniqueSections);
        } catch (error) {
          console.error("Error fetching class and section data:", error);
        }
      };
  
      fetchData();
    }, []);
  
    const fetchSubjects = async (groupName, index) => {
      try {
        const subjectBaseURL = `${process.env.REACT_APP_nodewsPrefix}/getClassSubjects`;
        const response = await axios.post(
          subjectBaseURL,
          {
            class_name: formValues[index].stdClass + formValues[index].stdsection,
            schoolcode: userinfo[0]?.institution,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );
        const newFormValues = [...formValues];
        newFormValues[index].subjects = response.data.subject_data;
        setSubjectsVal(newFormValues[index].subjects);
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    };
  
    const handleFormSubmit = async (event) => {
      event.preventDefault();
      const createEventURL = `${process.env.REACT_APP_nodewsPrefix}/createExamEvent`;
      try {
        await axios.post(createEventURL, {
            title: formValues.title,
            description: formValues.description,
            repeats: 0,
            stdClass: formValues.stdClass,
            section: formValues.stdsection,
            courseid: formValues.subject,
            eventtype: "course",
            year: String(formValues[0].date.$y),
            month: formValues.date.$M + 1,
            date: formValues.date.$D,
            hour: formValues.date.$H,
            minute: formValues.date.$m,
            token: token,
            instituteid: userinfo[0]?.institution,
          }, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });
        alert("Exam events created successfully");
      } catch (error) {
        console.error("Error while creating events:", error);
      }
    };
  
    return (
        <></>
    //   <form onSubmit={handleFormSubmit}>
    //     <TextField
    //       id="examname"
    //       label="Exam Name"
    //       variant="outlined"
    //       name="examname"
    //       value={formValues.title}
    //       onChange={(event) => handleInputData(index, event)}
    //       fullWidth
    //       required
    //     />
     
    //       <Grid container spacing={1} mt={1} key={index}>
    //         <Grid item md={2}>
    //           <FormControl variant="outlined" fullWidth required>
    //             <InputLabel id="stdClass-label">Class</InputLabel>
    //             <Select
    //               labelId="stdClass-label"
    //               id="stdClass"
    //               name="stdClass"
    //               value={formValues.stdClass}
    //               onChange={(event) => handleInputData(index, event)}
    //               label="Class"
    //             >
    //               {classes.map((cls) => (
    //                 <MenuItem key={cls} value={cls}>
    //                   {cls}
    //                 </MenuItem>
    //               ))}
    //             </Select>
    //           </FormControl>
    //         </Grid>
    //         <Grid item md={2}>
    //           <FormControl variant="outlined" fullWidth required>
    //             <InputLabel id="stdsection-label">Section</InputLabel>
    //             <Select
    //               labelId="stdsection-label"
    //               id="stdsection"
    //               name="stdsection"
    //               value={formValues.stdsection}
    //               onChange={(event) => handleInputData(index, event)}
    //               label="Section"
    //             >
    //               {sections.map((section) => (
    //                 <MenuItem key={section} value={section}>
    //                   {section}
    //                 </MenuItem>
    //               ))}
    //             </Select>
    //           </FormControl>
    //         </Grid>
    //         <Grid item md={3}>
    //           <FormControl variant="outlined" fullWidth>
    //             <InputLabel id="subject-label">Subject</InputLabel>
    //             <Select
    //               labelId="subject-label"
    //               id="subject"
    //               name="subject"
    //               value={formValues.subject}
    //               onChange={(event) => handleInputData(index, event)}
    //               label="Subject"
    //             >
    //               {formValues.subjects?.map((option) => (
    //                 <MenuItem key={option.courseid} value={option.courseid}>
    //                   {option.fullname}
    //                 </MenuItem>
    //               ))}
    //             </Select>
    //           </FormControl>
    //         </Grid>
    //         <Grid item md={2}>
    //           <LocalizationProvider dateAdapter={AdapterDayjs}>
    //             <DatePicker
    //               fullWidth
    //               label="Date"
    //               value={formValues.date}
    //               onChange={(date) => handleDateChange(index, date)}
    //               renderInput={(params) => <TextField {...params} fullWidth />}
    //               format="DD-MM-YYYY"
    //               sx={{ width: "100%" }}
    //             />
    //           </LocalizationProvider>
    //         </Grid>
    //         <Grid item md={2}>
    //           <TextField
    //             id="duration"
    //             label="Duration"
    //             variant="outlined"
    //             name="duration"
    //             value={formValues.duration}
    //             onChange={(event) => handleInputData(index, event)}
    //             fullWidth
    //           />
    //         </Grid>
           
    //       </Grid>
     
    //     <Box textAlign={"center"} mt={2}>
    //       <PrimaryBgBtn type="submit">Submit</PrimaryBgBtn>
    //     </Box>
    //   </form>
    );
  };
  
  export default EventPlanner;
  