import { Box } from "@mui/material";
import Header from "../_Components/Header/Header";
import Footer from "../_Components/Footer/Footer";
import { Outlet, useLocation } from "react-router-dom";
import CustomBreadCrumb from "../_Components/Utils/BreadCrumb/CustomBreadCrumb";
import ErrorBoundary from "../_Components/Routing/ErrorBoundry";

const MainLayout = (props) => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const originalUrl = location.pathname + location.search;
  const isHomeScreen = location.pathname === "/";
  return (
    // ErrorBoundary will help in giving "Something went  wrong" page if any error occurs on the screen
    <ErrorBoundary>
      <div className="mainWrapper">
        <Header token={token} />
        <main className={isHomeScreen ? "contentArea" : "homeContentArea"}>
          <Box>
            <CustomBreadCrumb originalUrl={originalUrl}/>
            <Outlet />
          </Box>
        </main>
        <Footer />
      </div>
    </ErrorBoundary>
  );
};

export default MainLayout;
