import { Box } from "@mui/material";
import axios from "axios";
import { createContext, useContext, useState, useEffect } from "react";

const PermissionsContext = createContext();
const nToken = localStorage.getItem("nToken");

const PermissionsProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("userInfo");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [department, setDepartment] = useState(() => {
    const storedUser = localStorage.getItem("userInfo");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      return parsedUser[0]?.department?.toLowerCase() || "guest";
    }
    return "guest";
  });

  const [permissions, setPermissions] = useState(null);

  const updateUser = (newUser) => {
    setUser(newUser);
    setDepartment(newUser[0]?.department?.toLowerCase() || "guest");
    localStorage.setItem("userInfo", JSON.stringify(newUser));
  };

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        let response;
        if (department === "developer") {
          response = await axios.get(
            `${process.env.REACT_APP_nodewsPrefix}/getDeveloperConfig?department=${department}`
          );
        } 
        /* else if(department === "guest"){
          response = await axios.get(
            `${process.env.REACT_APP_nodewsPrefix}/getConfigByDept?department=guest&schoolcode=1`
          );
        } 
         */else {
          response = await axios.get(
            `${process.env.REACT_APP_nodewsPrefix}/getConfigByDept?department=${department}&schoolcode=${user[0]?.institution}`
          );
        }
        console.log(response.data.info, " sssssssssssssssssssss");
        setPermissions(response.data.info);
      } catch (error) {
        localStorage.clear();
        setPermissions({
          "dashboard": {
              "read": true,
              "write": true,
              "canAccess": true
          }
      });
      }
    };

    if (department) {
      fetchPermissions();
    }
  }, [department]);

  useEffect(() => {
    if (user) {
      setDepartment(user[0]?.department?.toLowerCase() || "guest");
    }
  }, [user]);

  return (
    <PermissionsContext.Provider value={{ permissions, updateUser }}>
      {permissions === null ? (
        <Box className="loaderSec">
          <img
            src={`${process.env.REACT_APP_wsPrefix}/lms-assets/images/loader.gif`}
            alt="loader"
          />
        </Box>
      ) : (
        children
      )}
    </PermissionsContext.Provider>
  );
};

const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error("usePermissions must be used within a PermissionsProvider");
  }
  return context;
};

export { PermissionsProvider, usePermissions };
