import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Snackbar,
  SnackbarContent,
  TextField,
} from "@mui/material";
import CustomDialog from "../../_Components/Common/CustomDialog/CustomDialog";
import { useState } from "react";
import axios from "axios";
import {
  PrimaryBgBtn,
  PrimaryOutlineBtn,
} from "../../_Components/Common/Buttons/CustomBtns";

const ChangePassword = () => {
  const [open, setOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentErrorMessage, setCurrentErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const userid = userinfo[0]?.id;
  const token = localStorage.getItem("token");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentPassword("");
    setNewPassword("");
    setCurrentErrorMessage("");
    setErrorMessage("");
    setSuccessMessage("");
    setConfirmPassword("");
  };
  const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,15}$/;
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "currentPassword") {
      const newValue = value.slice(0, 15);
      setCurrentPassword(newValue);
    } else if (name === "newPassword") {
      const newValue = value.slice(0, 15);
      setNewPassword(newValue);
    } else if (name === "confirmPassword") {
      const newValue = value.slice(0, 15);
      setConfirmPassword(newValue);
    }
  };

  const handleSubmit = () => {
    // Perform validation
    if (!currentPassword) {
      setCurrentPassword("Please fill this field.");
      return;
    } else if (!newPassword) {
      setErrorMessage("Please fill this field.");
      return;
    } else if (!confirmPassword) {
      setErrorMessage("Please fill this field.");
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage("Confirm password does not match the new password.");
      return;
    }

    if (!pattern.test(newPassword)) {
      setErrorMessage(
        "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }

    // If all validations pass, you can make an API call to update the password

    const baseURL = `${process.env.REACT_APP_wsPrefix}/customwebservice/user/password.php?wstoken=${token}&wsfunction=password_change`;
    axios
      .put(baseURL, {
        oldpassword: currentPassword,
        newpassword: newPassword,
        confirmpassword: confirmPassword,
        userid: userid,
      })
      .then((response) => {
        console.log(response.data.success, "password");
        if (response.data.success === false) {
          setCurrentErrorMessage(response.data.error);
          setSuccessMessage(""); // Clear any previous success message
        } else {
          setSuccessMessage(response.data.success_msg);
          setCurrentErrorMessage(""); // Clear any previous error message
          setOpen(false);
        }
      })
      .catch((error) => {
        console.error("Error while changing password:", error);
        setCurrentErrorMessage("An error occurred. Please try again later.");
        setSuccessMessage(""); // Clear any previous success message
      });

    // Reset the form fields
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setErrorMessage("");
  };

  return (
    <>
      <PrimaryBgBtn onClick={handleOpen}>Change Password</PrimaryBgBtn>

      <CustomDialog isOpen={open} width={"xs"} onClose={handleClose}>
        <DialogTitle
          className="title"
          sx={{
            backgroundColor: "popup.titleHeaderColor",
            color: "popup.popupheadertextcolor",
          }}
        >
          Change Password
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              sx={{ mb: 2 }}
              label="Current Password"
              type="password"
              name="currentPassword"
              value={currentPassword}
              onChange={handleChange}
              error={Boolean(currentErrorMessage)}
              helperText={currentErrorMessage}
            />

            <TextField
              fullWidth
              sx={{ mb: 2, color: errorMessage ? "red" : "inherit" }}
              label="New Password"
              type="text"
              name="newPassword"
              value={newPassword}
              onChange={handleChange}
              error={Boolean(errorMessage)}
              helperText={errorMessage}
            />

            <TextField
              fullWidth
              sx={{
                mb: 2,
                color: errorMessage ? "red" : "inherit",
                borderColor: errorMessage ? "red" : "inherit",
              }}
              label="Confirm Password"
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleChange}
              error={Boolean(errorMessage)}
              helperText={errorMessage}
            />

            <Box textAlign={"center"}>
              <PrimaryBgBtn type="submit">Save</PrimaryBgBtn>
            </Box>

            {/* <Snackbar
              open={!!errorMessage}
              onClose={() => setErrorMessage("")}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={60000}
            >
              <SnackbarContent
                sx={{ backgroundColor: "redColor.color" }}
                message={errorMessage}
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setErrorMessage("")}
                  >
                    OK
                  </Button>
                }
              />
            </Snackbar> */}

            <Snackbar
              open={!!successMessage}
              onClose={() => setSuccessMessage("")}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={6000}
            >
              <SnackbarContent
                sx={{ backgroundColor: "greenColor.color" }}
                message={successMessage}
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => setSuccessMessage("")}
                  >
                    OK
                  </Button>
                }
              />
            </Snackbar>
          </form>
        </DialogContent>
      </CustomDialog>
    </>
  );
};

export default ChangePassword;
