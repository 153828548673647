import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { languages } from "../../App";
import axios from "axios";
import StudentRecord from "./StudentRecord";
import AddAttendance from "./AddAttendance";
import UploadAttendance from "./UploadAttendance";
import DownloadAllAttendance from "./DownloadAllAttendance";
import "./Attendance.scss";
import MainCard from "../../_Components/Common/Cards/MainCard";
import CircularLoader from "../../_Components/Common/Loader/CircularLoader";

const Attendance = () => {
  let curLanguage = useContext(languages);
  const token = localStorage.getItem("token");
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const financialYr = localStorage.getItem("financialYear");
  const [attendanceData, setAttendanceData] = useState(null);
  const [studentInfo, setStudentInfo] = useState([]);
  const nToken = localStorage.getItem("nToken");
  const [classTeacher, setClassTeacher] = useState([]);

  useEffect(() => {
    let studentsData = null;
    const fetchData = async () => {
      try {
        const baseURL = `${process.env.REACT_APP_nodewsPrefix}/getTeacherAssignedClassStudent?teacher_id=${userinfo[0]?.id}&schoolcode=${userinfo[0]?.institution}`;
        const response1 = await axios.get(baseURL, {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        });
        const classTeacherData = response1.data.rows.filter(
          (val) => val.is_classteacher === 1
        );
        setClassTeacher(classTeacherData);
        const enroledURL = `${process.env.REACT_APP_wsPrefix}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_enrol_get_enrolled_users_info&moodlewsrestformat=json&courseid=${classTeacherData[0].courseid}`;
        const response2 = await axios.get(enroledURL);
        const studentData = response2.data.filter(
          (val) =>
            val.department.toLowerCase() === "student" &&
            val.section === classTeacherData[0].section &&
            val.institution === userinfo[0].institution
        );
        setStudentInfo(studentData);
        console.log(studentData, "studentData");
        studentsData = studentData;

        const studentIds = studentsData?.map((data) => data.id);

        console.log(studentIds, "classTeacherData");
        const attendanceUrl = `${process.env.REACT_APP_nodewsPrefix}/viewAttendance`;
        const response = await axios.post(
          attendanceUrl,
          {
            student_id: studentIds,
            class_id: "",
            section_id: "",
            financial_year: financialYr,
            day: "",
            month: "",
            schoolcode: userinfo[0].institution,
          },
          {
            headers: {
              Authorization: `Bearer ${nToken}`,
            },
          }
        );
        console.log(response.data, "responsesss");

        const updatedAttendanceData = studentIds.reduce(
          (content, studentId) => {
            content[studentId] = response.data.attendance_data[studentId];
            return content;
          },
          {}
        );
        setAttendanceData(updatedAttendanceData);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    fetchData();
  }, []);

  const handleAttendance = async () => {
    try {
      const studentIds = studentInfo.map((data) => data.id);
      const attendanceUrl = `${process.env.REACT_APP_nodewsPrefix}/viewAttendance`;
      const response = await axios.post(
        attendanceUrl,
        {
          student_id: studentIds,
          class_id: "",
          section_id: "",
          financial_year: financialYr,
          day: "",
          month: "",
          schoolcode: userinfo[0].institution,
        },
        {
          headers: {
            Authorization: `Bearer ${nToken}`,
          },
        }
      );
      const updatedAttendanceData = studentIds.reduce((content, studentId) => {
        content[studentId] = response.data.attendance_data[studentId];
        return content;
      }, {});

      setAttendanceData(updatedAttendanceData);
    } catch (error) {
      console.error("Error adding attendance:", error);
    }
  };
  const dataPres = studentInfo.length > 0;
  return (
    <MainCard
      cardTitle={curLanguage.attendance}
      mainPage
      additionalData={
        <Box>
          {dataPres && (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"end"}
              gap={2}
            >
              <AddAttendance
                studentInfo={studentInfo}
                attendanceinfo={attendanceData}
                onAttendanceUpdate={handleAttendance}
              />
              <DownloadAllAttendance
                studentInfo={studentInfo}
                attendanceinfo={attendanceData}
              />
              <UploadAttendance onAttendanceUpdate={handleAttendance} />
            </Box>
          )}
        </Box>
      }
    >
      {classTeacher.length === 0 ? (
         <Box display={"flex"} justifyContent="center">
         <Typography
           variant="h6"
           mt={1}
           style={{
             border: "2px solid #cfdcfd",
             backgroundColor: "#dfe8ff",
             padding: "20px",
             borderRadius: "15px",
           }}
         >
           As a subject teacher, you do not have permission to mark attendance
         </Typography>
       </Box>
      ) : (
        <Box>
          {dataPres ? (
            <StudentRecord
              studentInfo={studentInfo}
              attendanceData={attendanceData}
            />
          ) : (
            <CircularLoader />
          )}
        </Box>
      )}
    </MainCard>
  );
};

export default Attendance;
