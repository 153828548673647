// CustomDialog.js

import { Dialog, IconButton } from "@mui/material";
import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";

const CustomDialog = ({
  isOpen,
  onClose,
  children,
  className,
  width,
  disableBackdropOption,
  closeIcon,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className={className}
      maxWidth={width}
      fullWidth={true}
      disablebackdropclick={
        disableBackdropOption ? disableBackdropOption : true
      }
    >
      {closeIcon && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IoCloseCircleOutline size={40} color="#204abd" />
        </IconButton>
      )}
      {children}
    </Dialog>
  );
};

export default CustomDialog;
